document.addEventListener("DOMContentLoaded", function () {
  const header = document.getElementById("header");

  window.addEventListener("scroll", function () {
    if (window.scrollY > 1) {
      header.classList.add("is-scrolled");
    } else {
      header.classList.remove("is-scrolled");
    }
  });

  // フェードインアニメーション
  const fadeInEls = document.querySelectorAll(".inview");

  /* IntersectionObserverに渡すコールバック関数 */
  var cb = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        /* 監視対象が画面内に入ったときのアクション */
        entry.target.classList.add("is-inview"); // 画面内に入った要素にinviewクラスを付与
        observer.unobserve(entry.target); // 1度発火した後監視を止める
      }
    });
  };

  var options = {
    rootMargin: "-20% 0px",
  };

  var io = new IntersectionObserver(cb, options);

  fadeInEls.forEach((el) => {
    io.observe(el);
  });
});
